export default {
    1 : {
        id : 1,
        pic : "assets/images/icons/mat/lunch.svg"
    },
    2 : {
        id : 2,
        pic : "assets/images/icons/mat/lunch.svg"
    },
    3 : {
        id : 3,
        pic : "assets/images/icons/mat/lunch.svg"
    },
    4 : {
        id : 4,
        pic : "assets/images/icons/mat/frukost.svg"
    },
    5 : {
        id : 5,
        pic : "assets/images/icons/mat/kaffe.svg"
    },
    6 : {
        id : 6,
        pic : "assets/images/icons/mat/fika.svg"
    }
};
