import React from 'react'

import MainSlider from '../MainSlider';
import VenueFilters from '../VenueFilters';
import DescriptionData from '../DescriptionData';
import Facilities from '../Facilities';
import OptionsNearby from '../OptionsNearby';
import ShowSearchForm from '../ShowSearchForm';
import VenueMap from '../VenueMap';
import Media from 'react-media';


export default class Venue extends React.Component {
  render () {
    return (
      <div className="venue">

        <div className="container-fluid">
          <div className="row">

          <Media query="(max-width: 1200px)">
          {matches => matches ? (
              <div> </div>
          ) : (
              <div className="col-lg-3 ">
                <VenueFilters />
              </div>
          )}
          </Media>


            <div className="col-lg-9 col-md-12 col-sm-12" >
              <MainSlider />
              <div className ="showOnMobile">
              <Media query="(max-width: 1200px)">
              {matches => matches ? (
                  <div className="col-md-12 mobileSearch">
                    <VenueFilters />
                  </div>              ) : (
                      <p></p>
              )}
              </Media>
              </div>
                <div className="col-lg-6 col-md-12">
                    <div className="venue_info venue_about">
                        <DescriptionData />
                        <div className="read-more">
                            <button type="button" className="btn btn-success btn-xs">Mer information</button>
                        </div>
                    </div>

                  </div>

                  <div className="col-lg-6 col-md-12 paddingFix">
                    <div id="venue_rooms">

                    </div>
                  </div>

                <VenueMap />
                <OptionsNearby
                  venue={ 2 }
                  />
              </div>

          </div>
        </div>
      </div>
    );
  }
}
