export default {
    1 : {
        id : 1,
        pic : 'assets/images/icons/teknik/proj.svg'
    },
    2 : {
        id : 2,
        pic : "assets/images/icons/teknik/board.svg"
    },
    3 : {
        id : 3,
        pic : "assets/images/icons/teknik/proj_screen.svg"
    },
    4 : {
        id : 4,
        pic : "assets/images/icons/teknik/whiteboard.svg"
    },
    5 : {
        id : 5,
        pic : "assets/images/icons/teknik/notes.svg"
    },
    6 : {
        id : 6,
        pic : "assets/images/icons/teknik/wifi.svg"
    },
    7 : {
        id : 7,
        pic : "assets/images/icons/teknik/board.svg"
    },
    8 : {
        id : 8,
        pic : "assets/images/icons/teknik/board.svg"
    },
    9 : {
        id : 9,
        pic : "assets/images/icons/teknik/board.svg"
    },
    10 : {
        id : 10,
        pic : "assets/images/icons/teknik/speaker_mic.svg"
    },
    11 : {
        id : 11,
        pic : "assets/images/icons/teknik/board.svg"
    }

};
