import React from 'react';
import configSitting from '../configSitting';


export default class ListTec extends React.Component {

    renderItem (item){
        return (<li className="iconLi"><img className="svg" key={item.id} src={configSitting[item.id].pic}/></li>)
    }

    render () {
        let Lista = venueData.seats;
        return (
            <ul id="list2">{Lista.map(this.renderItem)}</ul>
        );
    }
}
