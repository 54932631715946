import React from 'react';
import ListSeat from '../ListSeat';
import ListTec from '../ListTec';
import ListFood from '../ListFood';

export default class List extends React.Component {

    renderItem(item){
        return (<img key={item.id} src={config[item.id].pic}/>)
    }

    render () {
        return (
            <div>
                <ul>
                    <h4>Teknologi</h4>
                    <ListTec />
                    <h4>Sittning</h4>
                    <ListSeat />
                    <h4>Mat</h4>
                    <ListFood />
                </ul>
            </div>
        );
    }
}
