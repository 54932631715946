export default {
    1 : {
        id : 1,
        pic : "assets/images/icons/sittning/Mingel.svg"
    },
    2 : {
        id : 2,
        pic : "assets/images/icons/sittning/Styrelse.svg"
    },
    3 : {
        id : 3,
        pic : "assets/images/icons/sittning/Skolsittning.svg"
    },
    4 : {
        id : 4,
        pic : "assets/images/icons/sittning/Ubord.svg"
    },
    5 : {
        id : 5,
        pic : "assets/images/icons/sittning/Obord.svg"
    },
    6 : {
        id : 6,
        pic : "assets/images/icons/sittning/Islands.svg"
    }
};
