import React from 'react'
import DescriptionData from '../DescriptionData';
//import config from '../config.js'
export default class MainSlider extends React.Component {

	state = {
		slides: venueData.plantImages
	}

  componentDidMount(){
    $(".venue_slider .bxslider").bxSlider({
								auto: false,
                slideMargin: 0,
                speed: 500,
                pager: !1,
                controls: 1,
                randomStart: !1,
                preloadImages: "all",
                adaptiveHeight: false,
                responsive: !0,
                touchEnabled: true,
                nextText: "",
                prevText: "",
                onSliderLoad: function(e) {}
            });
  }

  renderItem(item){
    return (
      <li key={`slide_${item.id}`} style={{backgroundImage: `url(${item.url})`}}>
			<div className="bxSliderGradientContainer">
				<div className ="bxSliderSeparator"> </div>
				<div className="bxSliderGradient">
                </div>
			</div>

			</li>
    );
  }

  // renderFacillitys(item) {
  //     return (
  //
  //     );
  // }

  render () {

    if(this.state.slides.length == 0){
      return (
        <div className="cleafix" style={{height: '180px'}}></div>
      );
    }

    return (
      <div className="venue_slider container">
        <ul className="bxslider">
          {this.state.slides.map(this.renderItem)}
        </ul>
                    <div className="sliderNameCont">
                        <h2 className="sliderName">{ venueData.plantName }</h2>
                        <p className="sliderNameDesc">{`${venueAddress.street} ${venueAddress.city.name}`}</p>
                    </div>
      </div>
    );
  }
}
