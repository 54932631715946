import React from 'react';
import configFoods from '../configFoods';



export default class ListFoods extends React.Component {

renderItem (item) {
    return (<img className="iconLi" key={item.id} id={item.id} src={configFoods[item.id].pic}/>)
}

    render () {
        let Lista = venueData.plantFoodBeverages;
        return (
                <li id="list3">{Lista.map(this.renderItem)}</li>
        );
    }
}
