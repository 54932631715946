import React from 'react';
import config from '../config';


export default class ListTec extends React.Component {

    renderItem(item){
        return (<li className="iconLi"><img className="svg" key={item.id} src={config[item.id].pic}/></li>)
    }

    render () {
        let List = venueData.technologies;

        return (
                    <ul id="list">{List.map(this.renderItem)}</ul>
        );
    }
}
