import React from 'react';

import ShowSearchForm from '../ShowSearchForm';
import List from '../List';


export default class VenueFilters extends React.Component {

	componentDidMount(){
        if ($(window).width() >= 1200) {
            $(".venue_filters_wrapper").sticky({topSpacing:0});
        }
	}

  render () {
    return (
			<div className="venue_filters_wrapper">
      <div className="venue_filters">
				<div id="venue_filters">
					<div>

					</div>
				</div>
				<ShowSearchForm />
					<div className="row">
						<div className="ok">
						<p className="form_notice">Ange antal personer och datum för din bokning</p>
						</div>
					</div>
      </div>
      <div className="venue_filters venue_filters_second">
				<div id="venue_filters_second">
					<div>
						<List />
					</div>
				</div>
      </div>
			</div>
    );
  }
}
